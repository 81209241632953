const openNav = (nav, overlay) => {
  nav.classList.add('active');
  overlay.classList.add('active');
  window.disableBodyScroll();
};

const closeNav = (nav, overlay) => {
  nav.classList.remove('active');
  overlay.classList.remove('active');
  window.enableBodyScroll();
};

const initNav = () => {

  const nav = document.querySelector('.mobile_nav');
  const overlay = document.querySelector('.overlay');

  setTimeout(() => {
    nav.style.opacity = 1;
  }, 1000);

  // Open
  document.querySelectorAll('[data-nav=open]').forEach((button) => {
    button.addEventListener('click', () => {
      openNav(nav, overlay);
    });
  });

  // Close
  document.querySelectorAll('[data-nav=close]').forEach((button) => {
    button.addEventListener('click', () => {
      closeNav(nav, overlay);
    });
  });

  // Overlay
  overlay.addEventListener('click', () => {
    closeNav(nav, overlay);
  });
};

export {initNav};
