import {tns} from './../../../node_modules/tiny-slider/src/tiny-slider';

function initTinySlider() {
  document.querySelectorAll('[data-slider]').forEach((slider) => {
    const setting = JSON.parse(slider.getAttribute('data-slider'));
    tns(setting);
  });
}

export {initTinySlider};
