const typeAdditional = {
  'МЕТ/ПЕ': ['ziplock', 'handle', 'incision', 'suspension', 'fold'],
  'ПА/ПЕ': [],
  'ПEТ/ПE': ['ziplock', 'handle', 'incision', 'suspension'],
  'Брендированные': ['ziplock', 'handle', 'incision', 'suspension', 'fold'],
  'Дой-пак': ['ziplock', 'handle', 'incision', 'suspension'],
};

const allAdditional = document.querySelectorAll('input[name="additional"]');
const block = document.querySelector('#additionals');

const additionalSwitch = (value) => {
  allAdditional.forEach((input) => {
    const additionalValue = input.getAttribute('id').replace('additional-', '');
    if (typeAdditional[value].indexOf(additionalValue) !== -1) {
      input.parentElement.style.display = 'block';
    } else {
      input.checked = false;
      input.parentElement.style.display = 'none';
    }
  });

  if (typeAdditional[value].length > 0) {
    block.style.display = 'block';
  } else {
    block.style.display = 'none';
  }
};

export {additionalSwitch};
