import {openModal, setupModal} from './modal';

const closeCurrentModal = () => {
  document.querySelectorAll('[data-modal-close]').forEach((button) => {
    button.addEventListener('click', changeModal);
  });
};

const changeModal = (nextModal = '') => {
  const button = document.querySelector('.modal--active .modal__close-btn');
  if (button) {
    button.click();
  }
  if (nextModal.length > 0) {
    const modal = document.querySelector(`.modal--${nextModal}`);
    setupModal(modal);
    openModal(modal);
  }
};

export {closeCurrentModal, changeModal};
