import {addSize} from './calc/add-size';
import {inputFocus} from './calc/input-focus';
import {validate} from './form/validate';
import {calcChangeImage} from './calc/change-image';
import {calcData} from './calc/calc-data';
import {closeCurrentModal, changeModal} from './modals/change';
import {renderCheckout} from './calc/data';
import {send} from './calc/send';

const initCalc = () => {
  calcChangeImage();
  calcSubmit();
  calcData();
  inputFocus();
  addSize();
  checkoutSubmit();
  closeCurrentModal();
  startCalc();
  // quantityMinimum();
};

const startCalc = () => {
  // Выбор типа
  document.querySelector('input[name="type"]').click();

  // Размеры, количество
  document.querySelectorAll('input[name*="width"], input[name*="height"], input[name="quantity"]').forEach((input) => {
    const event = new Event('input');
    input.dispatchEvent(event);
  });
};

const calcSubmit = () => {
  const form = document.querySelector('#form-calc');
  form.addEventListener('submit', (event) => {
    event.preventDefault();

    if (!validate(form)) {
      renderCheckout();
      changeModal('checkout');
    }

  });
};

const checkoutSubmit = () => {
  const form = document.querySelector('#form-checkout');
  form.addEventListener('submit', (event) => {
    event.preventDefault();

    if (!validate(form)) {
      changeModal('success');
      send();
    }
  });
};

export {initCalc};
