const typeWeight = {
  'МЕТ/ПЕ': [75, 100, 120],
  'ПА/ПЕ': [65, 70, 80, 100],
  'ПEТ/ПE': [65, 75, 120],
  'Брендированные': [65, 75, 120],
  'Дой-пак': [65, 75, 100, 1200],
};

const weightSwitch = (value) => {
  const allWeight = document.querySelectorAll('input[name="weight"]');
  allWeight.forEach((input) => {
    const weightValue = Number(input.value);
    if (typeWeight[value].indexOf(weightValue) !== -1) {
      input.parentElement.style.display = 'block';
    } else {
      input.parentElement.style.display = 'none';
    }
  });

  const firstWeight = document.querySelector(`#weight-${typeWeight[value][0]}`);
  if (firstWeight) {
    firstWeight.click();
  }
};

export {weightSwitch};
