import {changeModal} from './modals/change';
import {validate} from './form/validate';
import {getValue} from './form/value';
import {sendEmail} from './service/unione';

const initQuick = () => {
  quickSubmit();
};

const quickSubmit = () => {
  const forms = document.querySelectorAll('.quick-form');
  forms.forEach((form) => {
    form.addEventListener('submit', (event) => {
      event.preventDefault();

      if (!validate(form)) {
        send(form);
        changeModal('success');
      }
    });
  });
};

const send = async (form) => {
  const values = getValue(form);
  const html = renderEmail(values);
  const result = await sendEmail(`Новая заявка от ${values.name}`, html);
  if (result) {
    form.reset();
  }
};

const renderEmail = (data) => {

  // Contact
  let messageContact = `
    <h3>Контактные данные</h3>
    <p style="color: slategray;">
  `;

  if (data.name) {
    messageContact += `<b>Имя</b>: ${data.name}<br>`;
  }

  if (data.telephone) {
    messageContact += `<b>Телефон</b>: ${data.telephone}<br>`;
  }

  messageContact += '</p>';

  return messageContact;
};

export {initQuick};
