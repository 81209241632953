const scrollButtons = document.querySelectorAll('[data-scroll]');

const initScroll = () => {
  scrollButtons.forEach((button) => {
    button.addEventListener('click', (event) => {
      event.preventDefault();

      const element = document.querySelector(button.dataset.scroll);
      if (element) {

        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition - 25;

        window.scrollBy({
          top: offsetPosition,
          behavior: 'smooth',
        });

        // Закрыть, если вызов из мобильного меню
        const mobileNav = document.querySelector('.mobile_nav');
        if (mobileNav.contains(event.target)) {
          document.querySelector('[data-nav="close"]').click();
        }
      }
    });
  });
};

export {initScroll};
