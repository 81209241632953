const validate = (form) => {

  let hasError = false;

  // Старые удаляем
  form.querySelectorAll('.input_error').forEach((element) => {
    element.classList.remove('input_error');
    const message = element.querySelector('.input-message');
    if (message) {
      message.remove();
    }
  });

  // Новые рисуем
  const inputs = form.querySelectorAll('.input');
  inputs.forEach((parent) => {
    const errorMessage = parent.getAttribute('data-error');
    const required = parent.getAttribute('data-required') !== null ? true : false;

    if (required && errorMessage) {
      const input = parent.querySelector('input[type=text], input[type=email], input[type=checkbox]:checked, input[type=radio]:checked, textarea');
      if (input.name === 'quantity' && parseInt(input.value, 10) < 100) {
        showError(parent, errorMessage);
        hasError = true;
      } else if (!input || input.value.length === 0) {
        showError(parent, errorMessage);
        hasError = true;
      }
    }

  });

  return hasError;
};

const showError = (parent, errorMessage) => {
  parent.classList.add('input_error');
  const errorMessageElement = document.createElement('div');
  errorMessageElement.classList.add('input-message');
  errorMessageElement.innerText = errorMessage;
  parent.append(errorMessageElement);
};

export {validate};
