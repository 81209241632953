import {searchParent} from './search-parent';

const inputFocus = () => {
  const inputs = document.querySelectorAll('input[type=text], input[type=email], input[type=checkbox], input[type=radio], textarea');
  inputs.forEach((input) => {
    input.removeEventListener('click', inputFocusHandler);
    input.addEventListener('click', inputFocusHandler);
  });
};

const inputFocusHandler = ({target}) => {
  const parent = searchParent(target, 'input');
  if (parent) {
    parent.classList.remove('input_error');
    const message = parent.querySelector('.input-message');
    if (message) {
      message.remove();
    }
  }
};

export {inputFocus};
