import {weightSwitch} from './weight-switch';
import {additionalSwitch} from './additional-switch';

const Data = {};
const checkoutElement = document.querySelector('#checkout ul');

const changeData = (name, value) => {
  Data[name] = value;

  if (Data['type'] && Data['height'] && Data['weight'] && Data['quantity']) {
    const total = calcTotal();
    Data['total'] = total;
  }

  if (name === 'type') {
    weightSwitch(value);
    additionalSwitch(value);
  }
};

const calcTotal = () => {
  // Totals
  let total = 0;
  let item = 0;

  // Const
  const extra = 1.45;
  const width = Data['width'];
  const height = Data['height'];
  const weight = Number(Data['weight']);
  const quantity = Number(Data['quantity']) ? Data['quantity'] : 0;

  // Calc of Type
  if (Data['type'] === 'ПА/ПЕ') {
    Data['width'].forEach((_width, key) => {
      item += (((170 / (1000 / (16.8 + 2 + (0.93 * (weight - 15))) * 1) * 2) * getExtraWidth(width[key]) * width[key] * height[key] / 1000 / 1000) * extra);
    });
  } else {
    let percent = 1;
    if (Data['type'] === 'МЕТ/ПЕ') {
      percent = 1.1;
    } else if (Data['type'] === 'ПА/ПЕ') {
      percent = 1.05;
    } else if (Data['type'] === 'Брендированные') {
      percent = 1.3;
    } else if (Data['type'] === 'ПЕТ/ПЕ') {
      percent = 1;
    } else if (Data['type'] === 'Дой-пак') {
      percent = 1.2;
    }

    Data['width'].forEach((_width, key) => {
      item += (((182.5 / (1000 / (0.96 * weight) * 1) * 2) * getExtraWidth(width[key]) * width[key] * height[key] / 1000 / 1000) * extra) * percent;
    });
  }

  // Calc of modification
  let modification = 1;
  if (Data['modification']) {
    Data['modification'].forEach((modify) => {
      modification += Number(modify) / 100;
    });
  }

  // Calc of Quantity
  let quantitySale = 1;
  if (quantity >= 150000) {
    quantitySale = 0.85;
  }
  total = `${(Math.round(item * quantity * quantitySale * modification * 100) / 100).toLocaleString()} ₽`;

  renderTotal(total);

  return total;
};

const renderTotal = (value) => {
  const totals = document.querySelectorAll('[data-calc-total');
  totals.forEach((element) => {
    element.innerHTML = value;
  });
};

const renderCheckout = () => {

  checkoutElement.innerHTML = '';
  checkoutElement.parentElement.style.display = 'block';

  if (Data['type']) {
    renderRow('type', Data['type']);
  }

  if (Data['width'] && Data['height'] && Data['weight'] && Data['width'].length === Data['height'].length) {
    Data['width'].forEach((_item, key) => {
      renderRow(`size-${key}`, `${Data['width'][key]}x${Data['height'][key]}x${Data['weight']}`);
    });
  }

  if (Data['additional']) {
    renderRow('additional', Data['additional']);
  }

  if (Data['quantity']) {
    renderRow('quantity', Data['quantity']);
  }

  if (Data['total']) {
    renderRow('total', Data['total']);
  }

};

const renderRow = (code, value) => {
  const newRow = document.createElement('li');
  newRow.classList.add('checkout-item');
  newRow.dataset.row = code;
  newRow.innerHTML = `<span>${getNameFromCode(code)}</span><span>${value}</span>`;
  checkoutElement.append(newRow);
};

const getNameFromCode = (code) => {
  let name = '';
  if (code === 'type') {
    name = 'Тип пакета';
  } else if (code === 'additional') {
    name = 'Доп. модификации';
  } else if (code === 'quantity') {
    name = 'Количество';
  } else if (code.indexOf('size') === 0) {
    name = 'Ширина х Высота х Толщина';
  } else if (code === 'total') {
    name = 'Примерная цена';
  }
  return name;
};

const getExtraWidth = (width) => {
  width = Number(width);
  if (width < 120) {
    return 1.8;
  } else if (width < 160) {
    return 1.5;
  } else {
    return 1.4;
  }
};

const clearData = () => {
  for (const prop of Object.getOwnPropertyNames(Data)) {
    delete Data[prop];
  }

  // Clear forms
  document.getElementById('form-checkout').reset();
  document.getElementById('form-calc').reset();
};

export {Data, renderCheckout, changeData, calcTotal, clearData};
