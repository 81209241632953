import {changeData} from './data';

const calcData = () => {

  const formCalc = document.querySelector('#form-calc');
  const formCheckout = document.querySelector('#form-checkout');

  // Listener
  const inputType = formCalc.querySelectorAll('input[type=radio]');
  inputType.forEach((input) => {
    input.removeEventListener('change', radioHandler);
    input.addEventListener('change', radioHandler);
  });

  const inputSize = formCalc.querySelectorAll('input[name*=width], input[name*=height]');
  inputSize.forEach((input) => {
    input.removeEventListener('input', sizeHandler);
    input.addEventListener('input', sizeHandler);
  });

  const inputQuantity = formCalc.querySelectorAll('input[name=quantity]');
  inputQuantity.forEach((input) => {
    input.removeEventListener('input', quantityHandler);
    input.addEventListener('input', quantityHandler);
  });

  const inputAdditional = formCalc.querySelectorAll('input[name=additional]');
  inputAdditional.forEach((input) => {
    input.removeEventListener('change', additionalHandler);
    input.addEventListener('change', additionalHandler);
  });

  const inputContact = formCheckout.querySelectorAll('input[type=text]:not([name=quantity]), input[type=email], textarea');
  inputContact.forEach((input) => {
    input.removeEventListener('input', textHandler);
    input.addEventListener('input', textHandler);
  });

};

const radioHandler = ({target}) => {
  changeData(target.name, target.value);
};

const sizeHandler = ({target}) => {

  const widths = [];
  const heights = [];

  // Clear
  target.value = target.value.replace(/\D/g, '');

  const inputs = document.querySelectorAll('input[name*=width], input[name*=height]');
  inputs.forEach(({name, value}) => {
    if (name.indexOf('width') === 0) {
      const onlyDigit = value.replace(/\D/g, '');
      widths.push(onlyDigit);
    } else if (name.indexOf('height') === 0) {
      const onlyDigit = value.replace(/\D/g, '');
      heights.push(onlyDigit);
    }
  });

  changeData('width', widths);
  changeData('height', heights);
};

const textHandler = ({target}) => {
  changeData(target.name, target.value);
};

const quantityHandler = ({target}) => {
  let onlyDigit = target.value.replace(/\D/g, '');

  target.value = onlyDigit;
  changeData(target.name, onlyDigit);
};

const additionalHandler = ({target}) => {

  const values = [];
  const modifications = [];

  const checked = document.querySelectorAll(`input[name="${target.name}"]:checked`);
  checked.forEach((input) => {
    values.push(input.value);
    modifications.push(input.dataset.modification);
  });

  changeData(target.name, values);
  changeData('modification', modifications);
};

export {calcData};
