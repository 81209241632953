const getValue = (form) => {
  const values = [];
  const inputs = form.querySelectorAll('.input');
  inputs.forEach((parent) => {
    const input = parent.querySelector('input[type=text], input[type=email], input[type=checkbox]:checked, input[type=radio]:checked, textarea');
    const name = input.getAttribute('name');
    const value = input.value;

    values[name] = value;
  });

  return values;
};

export {getValue};
