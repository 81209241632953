import {Data, clearData} from './data';
import {sendEmail} from './../service/unione';

const send = async () => {

  const html = renderEmail(Data);
  const result = await sendEmail(`Новый расчет от ${Data.name}`, html);
  if (result !== false) {
    clearData();
  }
};

const renderEmail = (data) => {

  // Contact
  let messageContact = `
    <h3>Контактные данные</h3>
    <p style="color: slategray;">
  `;

  if (data.name) {
    messageContact += `<b>Имя</b>: ${data.name}<br>`;
  }

  if (data.email) {
    messageContact += `<b>Email</b>: ${data.email}<br>`;
  }

  if (data.telephone) {
    messageContact += `<b>Телефон</b>: ${data.telephone}<br>`;
  }

  if (data.comment) {
    messageContact += `<b>Комментарий</b>: ${data.comment}<br>`;
  }

  messageContact += '</p>';

  // Sizes
  let messageItem = '';
  data.width.forEach((item, key) => {
    messageItem += `
      <h3>Расчет #${key + 1}</h3>
      <p style="color: slategray;">
        <b>Тип пакета</b>: ${data.type}<br>
        <b>Ширина</b>: ${data.width[key]}мм<br>
        <b>Высота</b>: ${data.height[key]}мм<br>
        <b>Толщина</b>: ${data.weight}мм<br>
        <b>Количество</b>: ${data.quantity}
      </p>
    `;
  });

  // Additional
  let messageAdditional = '';
  if (data.additional) {
    messageAdditional += '<h3>Дополнительно</h3><p style="color: slategray;">';
    data.additional.forEach((item) => {
      messageAdditional += `${item}<br>`;
    });
    messageAdditional += '</p>';
  }

  let messageTotal = '';
  if (data.total) {
    messageTotal += `<h3>Итого: ${data.total}`;
  }

  return messageContact + messageItem + messageAdditional + messageTotal;
};

export {send};
