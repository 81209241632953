const sendEmail = async (subject, html) => {

  const res = await fetch('https://eu1.unione.io/ru/transactional/api/v1/email/send.json', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'api_key': '6qp3doig9zz4zckyh989skr1kn4ad63xcgnxahfy',
      'message': {
        'recipients': [
          {
            'email': 'sales@vacprom.ru',
          }
        ],
        'body': {
          'html': html,
          'plaintext': 'Пришла новая заявка',
        },
        'from_email': 'sales@vacprom.ru',
        'subject': subject,
      },
    }),
  });

  if (!res.ok) {
    return false;
    // throw new Error('Could not fetch', res);
  }

  return await res.json();
};

export {sendEmail};
