const initFaq = () => {
  document.querySelectorAll('.faq-item').forEach((faq) => {
    faq.addEventListener('click', faqHandler);
  });
};

const faqHandler = (event) => {
  event.preventDefault();
  const faq = event.currentTarget;
  const answer = faq.querySelector('.faq-info__answer');

  // Active
  faq.classList.toggle('active');

  // Height
  const height = answer.scrollHeight;
  if (faq.classList.contains('active')) {
    answer.style.height = height + 'px';
  } else {
    answer.style.height = '0';
  }
};

export {initFaq};
