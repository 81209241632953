// import {ieFix} from './utils/ie-fix';
// import {polyfillObjectFit} from './utils/polyfill-object-fit';

import {iosVhFix} from './utils/ios-vh-fix';

import {initNav} from './modules/init-nav';
import {initModals} from './modules/modals/init-modals';
import {initTinySlider} from './modules/init-tns';
import {initFaq} from './modules/init-faq';
import {initScroll} from './modules/init-scroll';
import {initResetInput} from './modules/init-reset-input';
import {initQuick} from './modules/init-quick';

// ---------------------------------

window.addEventListener('DOMContentLoaded', () => {

  // Utils
  // ---------------------------------

  // только если ie11
  // ieFix();
  // https://github.com/fregante/object-fit-images
  // polyfillObjectFit();

  iosVhFix();

  // Modules
  // ---------------------------------

  // все скрипты должны быть в обработчике 'DOMContentLoaded', но не все в 'load'
  // в load следует добавить скрипты, не участвующие в работе первого экрана
  window.addEventListener('load', () => {
    initNav();
    initModals();
    initTinySlider();
    initFaq();
    initScroll();
    initQuick();

    // document.querySelector('#button-test').addEventListener('click', (event) => {
    //   event.preventDefault();
    //   send();
    // });
  });
});

// ---------------------------------

// используйте .closest(el)

// для адаптивного JS используейтся matchMedia и addListener
// const breakpoint = window.matchMedia(`(min-width:1024px)`);
// const breakpointChecker = () => {
//   if (breakpoint.matches) {
//   } else {
//   }
// };
// breakpoint.addListener(breakpointChecker);
// breakpointChecker();

// привязывайте js не на бэм, а на data-cookie

// выносим все в data-attr - url до пинов карты, настройки автопрокрутки, url к json и т.д.
