import {searchParent} from './search-parent';
import {inputFocus} from './input-focus';
import {calcData} from './calc-data';

let sizeKey = 1;
const buttonAdd = document.querySelector('[data-size-add]');
const buttonRemove = document.querySelector('[data-size-remove]');

const addSize = () => {
  buttonAdd.removeEventListener('click', addSizeHandler);
  buttonAdd.addEventListener('click', addSizeHandler);

  buttonRemove.removeEventListener('click', removeSizeHandler);
  buttonRemove.addEventListener('click', removeSizeHandler);
};

const addSizeHandler = () => {
  event.preventDefault();

  sizeKey++;

  const button = document.querySelector('[data-size-add]').parentElement;
  button.insertAdjacentHTML('beforebegin', `
    <div class="input-grid input-grid_1_md_2">
      <div class="input" data-error="Введите ширину" data-required>
        <label class="input-title">Ширина открытый край #${sizeKey}</label>
        <div class="input-text">
          <input type="text" name="width[${sizeKey}]" inputmode="numeric" placeholder="Введите ширину">
          <button class="input-reset" type="button" onclick="clearInput(this)" tabindex="-1">
            <svg width="16" height="16" aria-hidden="true">
              <use xlink:href="#input-reset"></use>
            </svg>
          </button>
        </div>
      </div>
      <div class="input" data-error="Введите длину" data-required>
      <label class="input-title">Длина (высота) пакета #${sizeKey}</label>
        <div class="input-text">
          <input type="text" name="height[${sizeKey}]" inputmode="numeric" placeholder="Введите длину (высоту)">
          <button class="input-reset" type="button" onclick="clearInput(this)" tabindex="-1">
            <svg width="16" height="16" aria-hidden="true">
              <use xlink:href="#input-reset"></use>
            </svg>
          </button>
        </div>
      </div>
    </div>
  `);

  // Show remove button
  buttonRemove.style.display = 'block';

  // Refresh
  inputFocus();
  calcData();
};

const removeSizeHandler = () => {
  event.preventDefault();

  if (sizeKey === 1) {
    return;
  }

  const input = document.querySelector(`[name="width[${sizeKey}]"]`);
  const parent = searchParent(input, 'input-grid');

  if (parent) {
    parent.remove();
  }

  sizeKey--;

  // Hide remove button
  if (sizeKey === 1) {
    buttonRemove.style.display = 'none';
  }

  // Refresh
  inputFocus();
  calcData();
};

export {addSize};
