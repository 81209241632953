const calcChangeImage = () => {
  const inputs = document.querySelectorAll('input[data-calc-image]');
  const image = document.querySelector('img[data-calc-image]');
  inputs.forEach((input) => {
    input.addEventListener('click', ({target}) => {
      const src = target.getAttribute('data-calc-image');
      image.setAttribute('src', src);
    });
  });
};

export {calcChangeImage};
